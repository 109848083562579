import React, { useEffect } from 'react';

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to; // Redireciona para o site externo
  }, [to]);

  return null; // Este componente não renderiza nada na tela
};

export default ExternalRedirect;